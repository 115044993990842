.project {
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  overflow: hidden;
  background-color: rgb(244, 244, 244);
  color: black;
  border-radius: 8px;
  box-shadow: 0px 3px 2px 0px rgb(109, 109, 109);
  transform: scale(1);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.project:hover {
  background-color: rgb(220, 220, 220);
  animation: pulse 500ms ease-in-out infinite;
}

.image {
  width: 128px;
  height: 128px;
  border-radius: 4px;
}

.content {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.titles {
  color: inherit;
}
.link {
  color: inherit;
  text-decoration: none;
}
.title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 8px;
}
.subtitle {
  margin: 0;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
}

.body {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  padding: 1rem;
}

.tech {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}
.techPill {
  font-size: 0.7rem;
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
  background-color: rgb(149, 149, 149);
  color: white;
}
.when {
  font-size: 1.25rem;
  color: rgb(63, 63, 63);
  margin: 0;
}

.gifContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gif {
  height: 400px;
  width: auto;
}
