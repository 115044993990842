html {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  /* background: linear-gradient(
    90deg,
    rgba(176, 171, 139, 1) 0%,
    rgba(2, 201, 249, 1) 100%
  ); */
}
