.profile {
  width: 100%;
  padding: 16px;
  position: relative;
}

.picContainer {
  display: flex;
  flex-direction: row;
}

.name {
  font-size: 20px;
  font-weight: bold;
}

.jobtitle {
  font-size: 14px;
  margin-top: 0;
  color: gray;
}

.pic {
  width: 64;
  height: 64px;
  border-radius: 500px;
  margin-right: 1rem;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.icon {
  width: 2rem;
}
