.appContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.app {
  width: 100%;
  max-width: 800px;
  padding-bottom: 32px;
}
